/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import { Header } from "./header"
import Footer from "./footer"
import styled from "styled-components"
import { SVGColors } from "./svgColors"
import CookieConsent from "react-cookie-consent";

import { DefaultOptionsQuery } from "../hooks/defaultOptions"

const StyledMain = styled.main`
      flex: 1;
`

const Layout = ({ children }) => {
  const defaultOptions = DefaultOptionsQuery()

  return (
    <>
      <Header siteTitle={defaultOptions.site.siteMetadata.title}/>
      <StyledMain>
        <SVGColors/>
        {children}
        <CookieConsent
          location="bottom"
          buttonText="Ok"
          cookieName="gatsby-gdpr-google-analytics"
          style={{
            background: "#2B373B",
            width: "auto" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          {defaultOptions.wp.startseite.acfThemeSettings.cookie_layer_text}
        </CookieConsent>
      </StyledMain>
      <Footer siteTitle={defaultOptions.site.siteMetadata.title}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
