import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import logo from "../images/logo.svg"
import { device } from "../utils/devices"

const StyledHeader = styled.header`
        backdrop-filter: saturate(180%) blur(7px);
        background: rgba(255, 255, 255, 0.7);
        margin-bottom: 0;

        color: black;
        position: sticky;
        top: 0;
        z-index: 3;       
        &:after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: -100%;
        height: 100%;
        z-index: -1;
        background: transparent;
        pointer-events: none;
        box-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.09);
        }
`

const StyledLink = styled(Link)`
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      position: relative;
 
      &,
      &:hover {
           text-decoration: none;
      } 
      &:after {
        content: '';
        width: 100%;
        background: black;
        opacity: 0;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: -5px;
        transition: opacity .3s ease;
        }        
      &.active, 
      &:hover {
        &:after {
          opacity: 1;
        }
      }
`

const StyledRow = styled.div`
      padding-top: 25px;
      padding-bottom: 25px;
      position: static;
`

const StyledList = styled.ul`~

      padding: 0;
      margin-bottom: 0;
      margin-left: auto;
      display: flex;
      justify-content: space-around;
      z-index: 2;
      @media ${device.mobile} {     
        justify-content: flex-end;
      }
      top: 100%;
      li {
        list-style: none;        
        &:not(:first-of-type) {
          margin-left: 30px;   
          }   
      }
`

const StyledCloseLabel = styled.label`
    display: none;
    &:after {
          content: '';
          opacity: .2;
          background-color: black;
          position: fixed;
          width: 100%;
          height: 100vh;
          top:0;
          left: 0;
          bottom: 0;
          z-index: 1;
    }  
`

const StyledCol = styled.div`
  position: relative;
  align-items: center;
  display: flex;
`

const StyledBurgerInput = styled.input`
    position: absolute;
    right: 6%;
    display: none;
    top: 50%;
    width: 0;
    height: 0;
    visibility: hidden;
    &:after {
      content: '';
      position: absolute;
      width: 30px;     
      height: 30px;
      left: -15px;
      top: -15px;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' role='img' focusable='false'><title>Menu</title><path stroke='currentColor' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'></path></svg>");
      visibility: visible;
    }

    @media ${device.tabletDown} {
      + ${StyledList} {
        position: fixed;
        opacity: 0;
        visibility: hidden;
        left: 0;
        right: 0;
        top: 0;
        background: white;
        transition: all .2s ease;     
        display: block;
      li {
        text-align: center;
        font-size: 25px;
        margin: 15px 0 15px 0;
      }            
    }
   
    &:checked ~ ${StyledList} {
      top: 100px;
      opacity: 1;
      visibility: visible;
    }
    
    &:checked ~ ${StyledCloseLabel} {
      display: block;
    }
  }
  
 
  @media ${device.tabletDown} {
    display: block;
  }  
`

export const Header = () => {
  const data = useStaticQuery(
    graphql`
        query {
            wpMenu(slug: {eq: "main-menu"}) {
                id
                name
                menuItems {
                    nodes {
                        path
                        label
                    }
                }
            }
        }
    `,
  )

  return (
    <StyledHeader>
      <div className={"container"}>
        <StyledRow className={"row align-items-center justify-content-center"}>
          <StyledCol className="col-10 col-md-12">
            <Link to="/">
              <img src={logo} alt=""/>
            </Link>
            <StyledBurgerInput id="menu" name="menu" type="checkbox"/>
            <StyledList>
              {data.wpMenu.menuItems.nodes.map((node, index) => {
                return (
                  <li key={index}>
                    <StyledLink
                      activeClassName="active"
                      partiallyActive={true}
                      to={node.path}>
                      {node.label}
                    </StyledLink>
                  </li>
                )
              })}
            </StyledList>
            <StyledCloseLabel htmlFor="menu"/>

          </StyledCol>
        </StyledRow>
      </div>
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
