import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import logo_w from "../images/logo_w.svg"
import { device } from "../utils/devices"

const StyledFooter = styled.footer`
    background: black;
    color: white;
    a {
      color: white;
    }
`
const StyledLogo = styled.div`
    a {
      color: white;
      margin: 50px;
    }
`

const StyledList = styled.ul`
      margin-bottom: 0;
      display: flex;
      padding: 30px 0;
      justify-content: space-around;
      li {
          list-style: none;        
      }
      @media ${device.tablet} {
          justify-content: center;
          li {
            margin: 0 30px ;
          }
      }

`

export default function Footer({ siteTitle }) {
  const data = useStaticQuery(
    graphql`
        query {
            wpMenu(slug: {eq: "footer-menu"}) {
                id
                name
                menuItems {
                    nodes {
                        path
                        label
                    }
                }
            }            
        }
    `,
  )

  return (
    <StyledFooter>
      <div className={"container"}>
        <StyledLogo className={"row justify-content-center"}>
          <Link to="/">
            <img src={logo_w} alt={siteTitle}/>
          </Link>
        </StyledLogo>

        <div className={"row justify-content-center"}>
          <div className={"col-12"}>
            <nav>
              <StyledList>
                <li>
                  &#169; {(new Date().getFullYear())}
                </li>
                {data.wpMenu.menuItems.nodes.map((node, index) => {
                  return (
                    <li key={index}>
                      <Link to={node.path}>{node.label}</Link>
                    </li>
                  )
                })}
              </StyledList>
            </nav>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}
