import React from "react"
import styled from "styled-components"
import { device } from "../utils/devices"

const FatHeadline = `
  font-size: 36px;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  @media ${device.tablet} {
    font-size: 60px;
  }
`


const StyledHeadlineH1 = styled.h1`
      ${FatHeadline}
`

const StyledHeadlineH2 = styled.h2`
      ${FatHeadline}
`

const StyledHeadlineH3 = styled.h3`
      ${FatHeadline}
`


const StyledTitle = styled.h2`
      font-size: 20px;
      line-height: 1;
      @media ${device.tablet} {
        font-size: 40px;
      }
`

const StyledSubline = styled.h3`
      font-size: 18px;
      @media ${device.tablet} {
        font-size: 20px;
      }
`

const StyledThinSubline = styled.h3`
      font-size: 16px;
      font-weight: normal;
`

export const StyledHeadlineWrapper = styled.div`
      margin-bottom: 30px;
      margin-top: 80px;
`

export const Headline = (data) => (
  <>
    {data.headlineH1 && <StyledHeadlineH1 dangerouslySetInnerHTML={{ __html: data.headlineH1 }}/>}
    {data.headlineH2 && <StyledHeadlineH2 dangerouslySetInnerHTML={{ __html: data.headlineH2 }}/>}
    {data.headlineH3 && <StyledHeadlineH3 dangerouslySetInnerHTML={{ __html: data.headlineH3 }}/>}
    {data.title && <StyledTitle dangerouslySetInnerHTML={{ __html: data.title }}/>}
    {data.subline && <StyledSubline dangerouslySetInnerHTML={{ __html: data.subline }}/>}
    {data.thinSubline && <StyledThinSubline dangerouslySetInnerHTML={{ __html: data.thinSubline }}/>}
  </>
)

