import { useStaticQuery, graphql } from "gatsby"

export const DefaultOptionsQuery = () => (
  useStaticQuery(
    graphql`
        query {
            wp {
                startseite {
                    acfThemeSettings {
                        homepageHeadline
                        homepageSubline
                        homepageHashtag
                        homepageIntro
                        homepageIntroButtonText
                        homepageLeistungenHeadline
                        contactSubline
                        contactDanke
                        contactHeadline
                        cookieLayerText
                    }
                }
            }
            site {
                siteMetadata {
                    title
                }
            }
        }
    `,
  )
)

