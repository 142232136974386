const size = {
  mobileMin: '320px',
  mobileMax: '767px',
  tabletMin: '768px',
  tabletMax: '1023px',
  laptopMin: '1024px',
  laptopMax: '1439px',
  desktopMin: '1440px'
}

export const device = {
  mobile: `(min-width: ${size.mobileMin})`,
  mobileDown: `screen and (max-width: ${size.mobileMax})`,
  tablet: `(min-width: ${size.tabletMin})`,
  tabletDown: `screen and (max-width: ${size.tabletMax})`,
  laptop: `(min-width: ${size.laptopMin})`,
  laptopDown: `screen and (max-width: ${size.laptopMax})`,
  desktop: `(min-width: ${size.desktopMin})`
};
