import React from "react"
import styled from "styled-components"

const StyledSVG = styled.svg`
position: absolute;
height: 0;
width: 0;
visibility: hidden;
`

/*
Get gradients from https://yoksel.github.io/svg-gradient-map/#/
 */

export const SVGColors = () => (

  <StyledSVG className="defs-only">
    <filter id="red" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
      <feComponentTransfer in="colormatrix" result="componentTransfer">
        <feFuncR type="table" tableValues="0.22 0.99"/>
        <feFuncG type="table" tableValues="0.15 0.21"/>
        <feFuncB type="table" tableValues="0.56 0.3"/>
        <feFuncA type="table" tableValues="0 1"/>
      </feComponentTransfer>
      <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
    </filter>

    <filter id="yellow" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
      <feComponentTransfer in="colormatrix" result="componentTransfer">
        <feFuncR type="table" tableValues="0.22 1"/>
        <feFuncG type="table" tableValues="0.15 0.69"/>
        <feFuncB type="table" tableValues="0.56 0.1"/>
        <feFuncA type="table" tableValues="0 1"/>
      </feComponentTransfer>
      <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
    </filter>


    <filter id="green" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
      <feComponentTransfer in="colormatrix" result="componentTransfer">
        <feFuncR type="table" tableValues="0.22 0.2"/>
        <feFuncG type="table" tableValues="0.15 0.84"/>
        <feFuncB type="table" tableValues="0.56 0.66"/>
        <feFuncA type="table" tableValues="0 1"/>
      </feComponentTransfer>
      <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
    </filter>



    <filter id="blue" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
      <feComponentTransfer in="colormatrix" result="componentTransfer">
        <feFuncR type="table" tableValues="0.22 0.95"/>
        <feFuncG type="table" tableValues="0.15 0.55"/>
        <feFuncB type="table" tableValues="0.56 0.39"/>
        <feFuncA type="table" tableValues="0 1"/>
      </feComponentTransfer>
      <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
    </filter>
  </StyledSVG>
)
